<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" />
          <span>Genel Bilgi</span>
        </template>
        <b-card>
          <blog-category />
          <title-text />
          <summary-text />
          <content-text />
        </b-card>
        <image-card />
      </b-tab>
      <b-tab
        :disabled="!itemData.id"
        lazy
      >
        <template #title>
          <FeatherIcon icon="SettingsIcon" />
          SEO Ayarları
        </template>
        <seo-form
          :item-id="itemData.id"
          id-components="23"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCard, BTab, BTabs,
} from 'bootstrap-vue'

import BlogCategory from '@/views/Admin/Blog/elements/BlogCategory.vue'
import TitleText from '@/views/Admin/Blog/elements/Title.vue'
import SummaryText from '@/views/Admin/Blog/elements/Summary.vue'
import ContentText from '@/views/Admin/Blog/elements/Content.vue'
import ImageCard from '@/views/Admin/Blog/elements/Image.vue'
import SeoForm from '@/views/Admin/Seo_configs/ItemForm.vue'

export default {
  name: 'ItemForm',
  components: {
    SeoForm,
    BTab,
    BTabs,
    BCard,
    BlogCategory,
    TitleText,
    SummaryText,
    ContentText,
    ImageCard,
  },
  computed: {
    itemData() {
      return this.$store.getters['blog/dataItem']
    },
  },
}
</script>
